import React from 'react';
import { Card, Col, Row, Collapse } from 'antd';
import './App.css';

const { Panel } = Collapse;
function App() {
    return (
        <div className="App"><Collapse defaultActiveKey={['1', '2']}>
            <Panel showArrow={false} header="室内" key="1" className="my-line">
                <div className="site-card-wrapper">
                    {/* <Space direction="vertical"> */}
                    <Row gutter={24}>
                        <Col span={8}>
                            <Card className="my-line" title="CPE01" bordered={false}>
                                <a href='https://10.10.1.254' target='_blank' rel="noreferrer">https://10.10.1.254</a>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card className="my-line" title="CPE02" bordered={false}>
                                <a href='https://10.10.2.254' target='_blank' rel="noreferrer">https://10.10.2.254</a>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card className="my-line" title="CPE03" bordered={false}>
                                <a href='https://10.10.3.254' target='_blank' rel="noreferrer">https://10.10.3.254</a>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card className="my-line" title="CPE04" bordered={false}>
                                <a href='https://10.10.4.254' target='_blank' rel="noreferrer">https://10.10.4.254</a>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card className="my-line" title="CPE05" bordered={false}>
                                <a href='https://10.10.5.254' target='_blank' rel="noreferrer">https://10.10.5.254</a>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card className="my-line" title="CPE06" bordered={false}>
                                <a href='https://10.10.6.254' target='_blank' rel="noreferrer">https://10.10.6.254</a>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Panel>
            <Panel showArrow={false} header="室外" key="2" className="my-line">
                <div className="site-card-wrapper">
                    <Row gutter={24}>
                        <Col span={8}>
                            <Card className="my-line" title="CPE11" bordered={false}>
                                <a href='https://10.20.1.254' target='_blank' rel="noreferrer">https://10.20.1.254</a>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card className="my-line" title="CPE012" bordered={false}>
                                <a href='https://10.20.2.254' target='_blank' rel="noreferrer">https://10.20.2.154</a>
                            </Card>
                        </Col>
                    </Row>
                    {/* </Space> */}
                </div>
            </Panel>
        </Collapse>
        </div>
    );
}

export default App;
